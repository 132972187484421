var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: _vm.alignment, attrs: { id: "promo-code" } }, [
    !_vm.success
      ? _c("div", [
          _c("div", { staticClass: "promo-code-toggle" }, [
            _c("a", { on: { click: _vm.toggleInput } }, [
              _vm._v("\n        Have a coupon code?\n      "),
            ]),
          ]),
          _vm.showInput
            ? _c("div", [
                _c(
                  "div",
                  { staticClass: "promo-code-input" },
                  [
                    _c("b-form-input", {
                      staticClass: "enter-code-input",
                      attrs: {
                        placeholder: "Enter code",
                        disabled: _vm.checkoutInProgress,
                      },
                      model: {
                        value: _vm.code,
                        callback: function ($$v) {
                          _vm.code = $$v
                        },
                        expression: "code",
                      },
                    }),
                    !_vm.loading
                      ? _c(
                          "b-button",
                          {
                            staticClass: "next-button",
                            attrs: {
                              variant: "primary",
                              "aria-label": "apply button",
                              disabled: _vm.checkoutInProgress,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.enterCode()
                              },
                            },
                          },
                          [_vm._v("\n          Apply\n        ")]
                        )
                      : _c(
                          "div",
                          { staticClass: "spinner" },
                          [
                            _c("b-spinner", {
                              attrs: { small: "", variant: "dark" },
                            }),
                          ],
                          1
                        ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ])
      : _vm._e(),
    !_vm.success && _vm.error
      ? _c("div", { staticClass: "error-msg" }, [
          _vm._v("\n    " + _vm._s(_vm.errorMessage) + "\n  "),
        ])
      : _vm.success
      ? _c("div", { staticClass: "success-msg" }, [
          _vm._v("\n    Coupon applied successfully!\n  "),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }