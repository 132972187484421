<!-- TODO create a single checkout component -->
<template>
  <div id="promo-code" :class="alignment">
    <div v-if="!success">
      <div class="promo-code-toggle">
        <a @click="toggleInput">
          Have a coupon code?
        </a>
      </div>
      <div v-if="showInput">
        <div class="promo-code-input">
          <b-form-input
            v-model="code"
            class="enter-code-input"
            placeholder="Enter code"
            :disabled="checkoutInProgress"
          />
          <b-button
            v-if="!loading"
            class="next-button"
            variant="primary"
            aria-label="apply button"
            :disabled="checkoutInProgress"
            @click="enterCode()"
          >
            Apply
          </b-button>
          <div v-else class="spinner">
            <b-spinner small variant="dark" />
          </div>
        </div>
      </div>
    </div>
    <div v-if="!success && error" class="error-msg">
      {{ errorMessage }}
    </div>
    <div v-else-if="success" class="success-msg">
      Coupon applied successfully!
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { createOrFindClientInteractionLog } from '@/common/modules/clientInteractionLog'

export default {
  name: 'PromoCode',
  props: {
    alignment: {
      type: String,
      default: 'center',
    },
    checkoutInProgress: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showInput: false,
      code: null,
      error: false,
      errorMessage: '',
      loading: false,
      currentPromoCode: null,
    }
  },
  computed: {
    ...mapGetters('checkout', [
      'promoCodes',
      'context',
    ]),
    success() {
      return !!this.promoCodes.length
    },
  },
  watch: {
    promoCodes(newVal, _oldVal) {
      if (newVal.length) {
        this.currentPromoCode = this.promoCodes[0]
        this.logInteraction('added-to-cart')
      } else if (!newVal.length && this.currentPromoCode) {
        this.logInteraction('removed-from-cart')
        this.currentPromoCode = null
      }
    },
  },
  mounted() {
    this.currentPromoCode = this.promoCodes.length ? this.promoCodes[0] : null
  },
  methods: {
    ...mapActions('checkout', [
      'addPromoCodeToCart',
    ]),
    toggleInput() {
      this.showInput = !this.showInput
      this.error = false
    },
    async enterCode() {
      this.loading = true

      try {
        const data = await this.addPromoCodeToCart(this.code)

        if (data.success) {
          this.error = false
          this.errorMessage = ''
        } else {
          this.error = true
          this.errorMessage = data.response.error
        }
      } catch {
        this.error = true
        this.errorMessage = 'An unexpected error occurred.'
      } finally {
        this.loading = false
      }
    },
    logInteraction(action = null) {
      if (!this.currentPromoCode || !this.context.companyId) return
      createOrFindClientInteractionLog({
        companyId: this.context.companyId,
        category: 'promo-codes',
        subCategory: 'promo-codes',
        objectId: this.currentPromoCode.product_id,
        objectTable: 'PromoCode',
        interaction: { action },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
#promo-code {
  display: flex;
  margin-right: auto;
  margin-top: 1em;

  .promo-code-toggle {
    align-self: flex-start;
    color: blue;
    padding: 0;
    font-weight: 800;
    cursor: pointer;
  }
  .promo-code-input {
    margin-top: 0.5em;
    display: flex;
    align-self: flex-end;
    justify-self: flex-end;

    .enter-code-input {
      margin-right: 1em;
      height: 42px !important;
      min-height: 36px !important;
      width: 10em;
    }
  }

  .spinner {
    display: flex;
    align-items: center;
  }

  .success-msg {
    color: $green;
  }

  .error-msg {
    color: $ct-ui-color-12;
    font-size: 14px;
  }
}

#promo-code.left {
  margin-left: 0;
  margin-right: 2em;
  height: 73px;

  .promo-code-toggle {
    text-align: left;
  }

  .error-msg {
    text-align: left;
    position: absolute;
    bottom: 0.5em;
    margin-bottom: 1em;
  }

  .success-msg {
    padding: 1em 1em 1em 0;
  }
}

#promo-code.center {
  display: inline-block;
  justify-content: center;

  .promo-code-toggle {
    text-align: center;
  }

  .promo-code-input {
    width: 100%;
    justify-content: center;
  }

  .error-msg, .success-msg {
    margin-top: 0.5em;
    text-align: center;
  }
}

@media only screen and (max-width: 768px) {
  #promo-code.left {
    width: 100%;
    height: auto;
    display: inline-block;
    justify-content: center;
    margin-left: auto;
    margin-right: auto !important;

    .promo-code-toggle {
      text-align: center;
    }

    .promo-code-input {
      justify-content: center;
    }

    .error-msg, .success-msg {
      margin-top: 1em;
      text-align: center;
      position: relative;
      display: block;
    }
  }
}
</style>
